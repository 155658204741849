// paper & background
$paper: #ffffff;

// primary
// $primaryLight: #e3f2fd;
// $primaryMain: #2196f3;
// $primaryDark: #1e88e5;
// $primary200: #90caf9;
// $primary800: #1565c0;


// $primaryLight: #ede7f6;
// $primaryMain: #05BBAA;
// $primaryDark: #05BBAA;
// $primary200: #BEE3DB;
// $primary800: #05BBAA;

$primaryLight: #ede7f6;
$primaryMain: #5E239D;
$primaryDark: #5E239D;
$primary200: #DBE4EE;
$primary800: #5E239D;


// secondary
// $secondaryLight: #ede7f6;
// $secondaryMain: #673ab7;
// $secondaryDark: #5e35b1;
// $secondary200: #b39ddb;
// $secondary800: #4527a0;

$secondaryLight: #ede7f6;
$secondaryMain: #5E239D;
$secondaryDark: #5E239D;
$secondary200: #DBE4EE;
$secondary800: #5E239D;


// success Colors
// $successLight: #b9f6ca;
// $success200: #69f0ae;
// $successMain: #00e676;
// $successDark: #00c853;

$successLight: #BEE3DB;
$success200: #BEE3DB;
$successMain: #05BBAA;
$successDark: #05BBAA;


// error
// $errorLight: #ef9a9a;
// $errorMain: #f44336;
// $errorDark: #c62828;

$errorLight: #eb5951;
$errorMain: #eb5951;
$errorDark: #eb5951;


// orange
// $orangeLight: #fbe9e7;
// $orangeMain: #ffab91;
// $orangeDark: #d84315;

$orangeLight: #fbe9e7;
$orangeMain: #FDEBB0;
$orangeDark: #eb5951;


// warning
$warningLight: #fff8e1;
$warningMain: #FDEBB0;
$warningDark: #FDEBB0;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
// $darkPrimaryLight: #e3f2fd;
// $darkPrimaryMain: #2196f3;
// $darkPrimaryDark: #1e88e5;
// $darkPrimary200: #90caf9;
// $darkPrimary800: #1565c0;

// $darkPrimaryLight: #BEE3DB;
// $darkPrimaryMain: #05BBAA;
// $darkPrimaryDark: #05BBAA;
// $darkPrimary200: #BEE3DB;
// $darkPrimary800: #05BBAA;

$darkPrimaryLight: #DBE4EE;
$darkPrimaryMain: #723aad;
$darkPrimaryDark: #8653bd;
$darkPrimary200: #DBE4EE;
$darkPrimary800: #5E239D;




// secondary dark
// $darkSecondaryLight: #d1c4e9;
// $darkSecondaryMain: #7c4dff;
// $darkSecondaryDark: #651fff;
// $darkSecondary200: #b39ddb;
// $darkSecondary800: #6200ea;
$darkSecondaryLight: #DBE4EE;
$darkSecondaryMain: #723aad;
$darkSecondaryDark: #8653bd;
$darkSecondary200: #DBE4EE;
$darkSecondary800: #5E239D;


// text variants
// $darkTextTitle: #d7dcec;
// $darkTextPrimary: #bdc8f0;
// $darkTextSecondary: #8492c4;

$darkTextTitle: #d7dcec;
$darkTextPrimary: #DBE4EE;
$darkTextSecondary: #BEE3DB;


// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}
