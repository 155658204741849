//fonts
@font-face {
  font-family: "Hammersmith One";
  src: url("../fonts/Hammersmith-One.ttf");
}

@font-face {
  font-family: "SegoeUI";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/SegoeUI-Bold.ttf");
}
@font-face {
  font-family: "SegoeUI";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/SegoeUI.ttf");
}
@font-face {
  font-family: "SegoeUI";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/SegoeUI-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Arabic";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Montserrat-Arabic-Bold.otf");
}

@font-face {
  font-family: "Montserrat-Arabic";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Montserrat-Arabic-Regular.otf");
}
@font-face {
  font-family: "Montserrat-Arabic";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Montserrat-Arabic-SemiBold.otf");
}

// color variants
@import "themes-vars.module.scss";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// custom
@import "_personalization.scss";

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

.chart-info-icon {
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }
}

.custom-search {
  &:focus,
  &:hover {
    border: 1px solid #5e239d;
  }
}

.fc .fc-timegrid-slot {
  height: 3.5em !important;
  border-bottom: 0;
}
.noScrollIndicator {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
// .fc-timegrid-event {
//   padding-top: 0 !important;
//   padding-bottom: 0 !important;
// }

.shape-circle {
  width: 20px;
  height: 20px;
  background: #5e239d;
  border-radius: 50%;
}

.shape-rectangle {
  width: 20px;
  height: 20px;
  background: #5e239d;
}

.fc-event {
  cursor: pointer;
}

.terms-link {
  text-decoration: none;
  font-weight: bold;
  color: #5e239d;
  &:visited {
    color: #5e239d;
  }
}

.MuiMultiSectionDigitalClock-root {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.preview-image-square {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.unaffected {
  direction: ltr;
}
