.adaptiveDrawer {
  height: auto;
  background: transparent;
  box-shadow: none;
}

.listing-select {
  .css-6hp17o-MuiList-root-MuiMenu-list {
    max-height: 150px;
  }
}

.check {
  input:disabled {
    cursor: not-allowed;
  }
}
.preview-image {
  min-width: 20vw !important;
  width: 0;
}
.chat-image {
  max-height: 150px !important;
}
.form-dia{
    .MuiDialog-container{
        min-width: 30%;
        .MuiPaper-rounded {
          min-height: 70%;
        }
    }
}

.rdw-editor-wrapper{
  max-height: 70%;
}
